<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isSearch="false"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="$router.go(-1)"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="求购标题" disabled v-model="topicTitle" />
      </template>
      <template #operateSlot="scope">
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { commentListUrl, commentDeleteUrl } from "./api.js";
export default {
  name: "purchasingCommentsManagement",
  data() {
    return {
      searchParam: {
        topicId: null,
      },
      topicTitle: null,
      tableUrl: commentListUrl,
      headers: [
        {
          prop: "commentContent",
          label: "评论内容",
        },
        {
          prop: "userName",
          label: "评论人",
        },
        {
          prop: "createTime",
          label: "评论时间",
        },
      ],
    };
  },
  created() {
    this.topicTitle = this.$route.query.topicTitle;
    this.searchParam.topicId = this.$route.query.id;
    this.$setBreadList("评论管理");
  },
  mounted() {},
  methods: {
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: item.commentId,
        };
        this.$axios
          .post(`${commentDeleteUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
